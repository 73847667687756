import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { createStyles } from "@material-ui/core"
import Link from "@material-ui/core/Link"
import { URLS } from "../../var/const"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

const useStyles = makeStyles((theme) => createStyles({
  button: {},
}))

const ChromeButton = () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
     file(relativePath: {eq: "buttons/chrome-badge.png"}) {
       childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`)
  return (
    <Link href={URLS.BROWSER_EXTENSION_CHROME} target="_blank" rel="noopener" underline={"none"}
          className={classes.button}>
      <Img fixed={data.file.childImageSharp.fixed} loading={"eager"} title={"Get it from the Chrome Web Store"}/>
    </Link>
  )
}

export default ChromeButton
