import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { createStyles } from "@material-ui/core"
import Link from "@material-ui/core/Link"
import { URLS } from "../../var/const"

const useStyles = makeStyles((theme) => createStyles({
  button: {
    width: '100%',
    maxWidth: 250,
    height: 54,
  },
}))

const ProductHuntButton = () => {
  const classes = useStyles()
  return (
    <Link href={URLS.PRODUCT_HUNT} target="_blank" rel="noopener" underline={"none"}
          className={classes.button}>
      <img className={classes.button}
           src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=203106&theme=light"
           alt="Memo Reminders - Simple yet powerful reminders for your browser | Product Hunt Embed"
           width="250px" height="54px"/>
    </Link>
  )
}

export default ProductHuntButton
